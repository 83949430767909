<template>
    <div class="icon-item">
         <div  @mouseleave="iconSize = '3x'" @mouseover="iconSize = '4x'" class="icon-item--wrapper icon-item--margin d-flex flex-column align-items-center">
            <div itemprop="image" class="icon-item__icon--wrapper mb-3">
                <font-awesome-icon :icon="icon" :size="iconSize" class="icon-item--color" />
            </div>
            <div class="icon-item__text-wrapper w-100">
                <h3 itemprop="description" class="icon-item__feature--style icon-item__feature-color">
                    {{ feature }}
                </h3>
            </div>
         </div>
    </div>
</template>

<script>
export default {
    name: 'icon-item',
    props: {
        icon: String, 
        feature: String
    }, 
    data() {
        return {
            iconSize: "3x", 
        }
    }
}
</script>

<style scoped>
    /* Text */
    .icon-item__feature--style{
        font-size: 16px;
        font-weight: 300;
        text-align: center;
    }
    .icon-item__feature-color{
        color: var(--ksr-iron);
    }
    /* Wrappers */
    .icon-item--wrapper{
        width: 280px; 
    }
    .icon-item__icon--wrapper{
        min-height: 64px;
    }
    /* Margins */
    .icon-item--margin{
        margin-bottom: 47px;
        transition: margin-bottom 0.3s;
    }
    /* Styles */
    .icon-item--color{
        color: var(--ksr-yellow);
        transition: all 0.3s;
    }
    @media (min-width: 768px) {
        .icon-item--margin{
            margin-bottom: 20px;
            transition: margin-bottom 0.3s; 
        }
    }
    @media (min-width: 992px) {
        .icon-item--margin{
            margin-bottom: 0px;
            transition: margin-bottom 0.3s; 
        }
    }
    
</style>
